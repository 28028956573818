<template>
  <div id="app" style="margin: 10px;">
    <v-card>
      <h1 style="margin-left: 10px">{{ }}</h1>
      <calendar-view
          @click-item="openURL"
          style="margin: 10px; min-height: 900px"
          :items="transformedEvents"
          :startingDayOfWeek="1"
          :show-date="currentDate"
          class="theme-default holiday-us-traditional holiday-us-official">>
      </calendar-view>
    </v-card>
  </div>
  <v-dialog
      v-model="eventDialog"
      width="1024">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="margin-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Ответственный"
                  v-model="event.responsible"
                  style="padding-bottom: 0px"
                  readonly>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="padding-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Площадка"
                  v-model="event.place.name"
                  style="margin-bottom: 0px">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row style="margin: 0px; padding-top: 0px">
                <v-text-field
                    variant="outlined"
                    label="Мероприятие"
                    v-model="event.name"
                    readonly>
                </v-text-field>
              </v-row>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field variant="outlined"
                            type="date"
                            label="Дата начала"
                            v-model="event.startDate"
                            readonly>
              </v-text-field>

            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  readonly
                  variant="outlined"
                  type="date"
                  label="Дата окончания"
                  v-model="event.endDate">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-data-table
          :headers="docsHeaders"
          :items="eventDocs">
          <template v-slot:[`item.name`]="{ item }" v-model="event.docs">
            <a :href="getDocUrl(item.name)" target="_blank">
              {{ item.name }}
            </a>
          </template>
        </v-data-table> 
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            variant="text"
            @click="eventDialog = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {CalendarView} from "vue-simple-calendar"

import '../../node_modules/vue-simple-calendar/dist/style.css';
import '../../node_modules/vue-simple-calendar/dist/css/default.css';
import API from '../auth.js'

export default {
  data: function () {
    return {
      eventDialog: false,
      showDate: new Date(new Date().setDate(1)),
      currentDate: null,
      events: [],
      transformedEvents: [],
      eventDocs: [],
      docsHeaders:[
        {title: '', key:'name', sortable: false, align: 'center'},
      ],
      api: process.env.VUE_APP_ENV_API,
      event: {},
    }
  },
  watch: {
    currentMonth() {
      this.currentDate = this.showDate.setMonth(this.currentMonth - 1)
    },
    event() {
      this.getEventDocs();
    },
  },
  props: {
    currentMonth: Number,
  },
  components: {
    CalendarView,
  },
  mounted() {
    this.getEvents()
  },
  methods: {
    openURL(e) {
      this.event = e.originalItem;
      this.eventDialog = true;
    },
    getEvents() {
      API.get(this.api + 'api/events/').then(response => {
        let eventsArray = [];
        response.data.message.forEach(event => {
          event.title = event.name
          eventsArray.push(event);
        });

        this.events = eventsArray;
        this.fillterEvents();
        
        this.transformedEvents = this.events.map(event => ({
            ...event,
            title: event.name || 'untitled'
          }));
      })
    },
    fillterEvents() {
      this.events = this.events.filter(a => a.status.name === 'assigned');
    },
    getDocUrl(fileName) {
      const baseUrl = "https://evdl.anosov.ru/docs/";
      return `${baseUrl}${fileName}`;
    },
    getEventDocs() {
      this.eventDocs = Object.values(this.event.docs || {});
    },
  }
}
</script>
<style>

</style>