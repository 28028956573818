<template>
  <div>
    <v-dialog
        v-model="newEventDialog"
        width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Добавление мероприятия</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  style="margin-bottom: 0px"
              >
                <v-text-field
                    variant="outlined"
                    label="Ответственный"
                    v-model="newEvent.responsible"
                    style="padding-bottom: 0px">
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  style="padding-bottom: 0px"
              >
                <v-autocomplete :items="places"
                                item-title="name"
                                :item-value="item => item"
                                variant="outlined"
                                label="Площадка"
                                v-model="newEvent.place"
                                style="margin-bottom: 0px">
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-row style="margin: 0px; padding-top: 0px">
                  <v-autocomplete
                      :items="filteringYearEvents"
                      item-title="name"
                      :item-value="item => item"
                      variant="outlined"
                      label="Мероприятие"
                      :v-model="newEvent"
                      @update:modelValue="handleEventChange">
                  </v-autocomplete>
                  <v-btn style="margin: 0.7rem" color="primary" variant="text"
                         @click="newYearEventDialog = true">Добавить
                  </v-btn>
                </v-row>
                <v-autocomplete
                    :items="failedEvents"
                    item-title="name"
                    :item-value="item => item"
                    variant="outlined"
                    label="Отложенные мероприятия"
                    :v-model="newEvent"> 
                </v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field variant="outlined"
                              type="date"
                              label="Дата начала"
                              v-model="newEvent.startDate">
                </v-text-field>

              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field variant="outlined"
                              type="date"
                              label="Дата окончания"
                              v-model="newEvent.endDate">
                </v-text-field>
              </v-col>

            </v-row>

          </v-container>
          <small>*Если мероприятие проводится в один день, то дата окончания не указывается</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              variant="text"
              @click="newEventDialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="primary"
              variant="text"
              @click="saveNewEvent()"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog height="auto" width="auto" v-model="newYearEventDialog">
      <v-card style="padding: 20px" elevation="11">
        <v-card-title>Создание нового мероприятия</v-card-title>
        <v-text-field variant="outlined"
                      label="Название мероприятия"
                      v-model="newYearEvent.name">
        </v-text-field>

        <v-autocomplete
                      :items="types"
                      item-title="title"
                      item-value="title"
                      variant="outlined"
                      label="Тип мероприятия"
                      v-model="newYearEvent.type">
        </v-autocomplete>

        <v-text-field variant="outlined"
                      type="date"
                      label="Дата мероприятия"
                      v-model="newYearEvent.startDate">
        </v-text-field>

        <v-row style="margin:0px">
          <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        </v-row>

        <v-row justify="center">
          <v-btn color="green" @click="createNewYearEvent()">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog height="auto" width="400px" v-model="editEventDialog">
      <v-card style="padding: 20px" elevation="11">
        <v-card-title style="text-align: center">Редактирование</v-card-title>
        <v-autocomplete :items="yearEvents"
                        item-title="name"
                        item-value="id"
                        variant="outlined"
                        label="Мероприятие"
                        v-model="editingItem.name">
        </v-autocomplete>
        <v-text-field variant="outlined"
                      label="Ответственный"
                      v-model="editingItem.responsible">
        </v-text-field>
        <v-autocomplete :items="places"
                        item-title="name"
                        :item-value="item => item"
                        variant="outlined"
                        label="Площадка"
                        v-model="editingItem.place"
                        style="margin-bottom: 0px">
                </v-autocomplete>
        <v-text-field variant="outlined"
                      type="date"
                      label="Дата начала"
                      v-model="editingItem.startDate">
        </v-text-field>
        <v-text-field variant="outlined"
                      type="date"
                      label="Дата окончания"
                      v-model="editingItem.endDate">
        </v-text-field>

        <v-data-table
          :headers="docsHeaders"
          :items="editingItemDocs">
          <template v-slot:[`item.name`]="{ item }" v-model="editingItem.docs">
            <a :href="getDocUrl(item.name)" target="_blank">
              {{ item.name }}
            </a>
          </template>
          <template v-slot:[`item.delete`]="{ item }" v-if="isAuth">
              <v-icon
                  size="small"
                  @click="deleteDocument(item)">
                mdi-delete
              </v-icon>
            </template>
        </v-data-table> 

        <v-row justify="center">
          <v-btn color="primary" @click="fileAdd = true">Добавить файл</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="saveEditEvent()">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileAdd" max-width="400px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Добавление файла</v-card-title>
        <v-row justify="center" class="mt-4">
            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        </v-row>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="addFileToEvent()">Добавить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeFileAdd()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Удалить запись?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="deleteItemConfirm()">Удалить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeDelete()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="docsDialogDelete" max-width="300px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Удалить документ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="deleteDocConfirm()">Удалить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeDelete()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snakbarVisible" color="primary"> {{ snakbarText }}
      <template v-slot:actions>
      <v-btn
          color="white"
          @click="snakbarVisible = false"
      >
        Закрыть
      </v-btn>
      </template>
    </v-snackbar>
    <v-card style="margin: 10px;">
      <v-row style="margin: 10px;">
        <v-autocomplete
            variant="solo-filled"
            color=primary
            item-title="month"
            item-value="id" label="Месяц" :items="months" v-model="currentMonth"></v-autocomplete>
        <v-autocomplete
            :items="types"
            item-title="title"
            color=primary
            :item-value="item => item" 
            variant="solo-filled"
            label="Тип мероприятия" 
            v-model="currentType">
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn v-if="isAuth" color="primary" style="margin: 10px" @click="newEventDialog = !newEventDialog">Добавить
        </v-btn>
      </v-row>
      <v-tabs color="primary" v-model="tab">
        <v-tab value="one">Календарь</v-tab>
        <v-tab value="two">Список мероприятий</v-tab>
      </v-tabs>

      <v-card>
        <v-window v-model="tab">
          <v-window-item value="one">
            <TableEvents :key="events" :currentMonth="currentMonth"></TableEvents>
          </v-window-item>

          <v-window-item value="two">
            <v-text-field
                style="margin: 15px"
                v-model="search"
                label="Поиск"
                prepend-inner-icon="mdi-magnify"
                single-line
                variant="outlined"
                hide-details
            ></v-text-field>
            <v-data-table :search="search"
                          class="elevation-1" :headers="headers" :items="fillteringEvents" :sort-by="sortBy">
              <template v-slot:[`item.delete`]="{ item}" v-if="isAuth">
                <v-icon
                    size="small"
                    @click="editEventDialog = true, this.editingItem = item">
                  mdi-pencil
                </v-icon>
                <v-icon
                    size="small"
                    @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:[`item.statuss`]="{ item}" v-if="isAuth">
                <v-btn v-if="item.status_id === 1" color="green" @click="pushComplete(item)"> Проведено</v-btn>
              </template>
            </v-data-table>
          </v-window-item>

        </v-window>
      </v-card>

    </v-card>

  </div>
</template>

<script>
import API from '../auth.js'
import TableEvents from "@/components/TableEvents.vue";
import axios from "axios";

export default ({
  watch: {
    editingItem() {
      this.getEditingDocs();
    },
    failedEventId() {
      this.changeEvent();
    },
    currentMonth() {
      this.fillterEvents();
    },
    currentType() {
      this.fillterEvents();
    },
    types() {
      this.fillterEvents();
    },
    events() {
      this.fillterEvents();
    },
    yearEvents() {
      this.fillterEvents();
    },
  },
  created() {
    this.getTypes();
    this.getAuth();
    this.getEvents();
    this.fillterEvents();
    //this.getResponsible();
    //this.getDisagreeEvents();
    this.getPlaces();
    this.currentMonth = new Date().getMonth() + 1;
  },
  components: {
    TableEvents,
  },
  data() {
    return {
      loading: false,
      search: null,
      failedEventId: null,
      newYearEvent: {},
      newYearEventDialog: false,
      currentMonth: Number,
      months: [{id: 9, month: 'сентябрь'},
        {id: 10, month: 'октябрь'},
        {id: 11, month: 'ноябрь'},
        {id: 12, month: 'декабрь'},
        {id: 1, month: 'январь'},
        {id: 2, month: 'февраль'},
        {id: 3, month: 'март'},
        {id: 4, month: 'апрель'},
        {id: 5, month: 'май'},
        {id: 6, month: 'июнь'},
      ],
      headers: [
        {title: 'Мероприятие', key: 'name'},
        {title: 'Ответственный', key: 'responsible', sortable: false},
        {title: 'Дата начала', key: 'startDate', sortable: false},
        {title: 'Дата окончания', key: 'endDate', sortable: false},
        {title: '', key: 'statuss', sortable: false},
        {title: '', key: 'delete', sortable: false},
      ],
      docsHeaders:[
        {title: '', key:'name', sortable: false, align: 'center'},
        {title: '', key: 'delete', sortable: false, align: 'center'}
      ],
      api: process.env.VUE_APP_ENV_API,
      newEventDialog: false,
      dialogDelete: false,
      docsDialogDelete: false,
      isAuth: true,
      fileAdd: false,
      sortBy: [{key: 'date_of_event', order: 'desc'}],
      editEventDialog: false,
      snakbarVisible: false,
      snakbarText: '',

      editingItem: {},
      deleteEvent: {},
      newEvent: {},
      newFailedEvent: {},
      editingItemDocs: [],
      deleteDoc: {},

      tab: null,
      responsibles: [],
      places: [],
      events: [],
      types: [],
      currentType: {},
      fillteringEvents: [],
      yearEvents: [],
      filteringYearEvents: [],
      file: Object,
      isLoadFile: false,
      failedEvents: [],
    }
  },
  methods: {
    addFileToEvent() {
      if (this.isLoadFile) {
        let formData = new FormData();
        formData.append('id', this.editingItem.id);
        formData.append('file', this.file);

        axios.post(this.api + 'api/events/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.isLoadFile = false;
          this.newEventDialog = false;
          this.snakbarText = "Файл успешно добавлен";
          this.snakbarVisible = true;
          this.getEvents(); 
          
          axios.get(this.api + 'api/events/' + this.editingItem.id)
            .then(response => {
              this.editingItem = response.data.message; 
              this.getEditingDocs();
            });
        });
      }
      this.fileAdd = !this.fileAdd;
    },
    closeDelete(){
      this.dialogDelete = false;
    },
    closeFileAdd(){
      this.fileAdd = false;
    },
    getDocUrl(fileName) {
      const baseUrl = "https://evdl.anosov.ru/docs/";
      return `${baseUrl}${fileName}`;
    },
    getEditingDocs() {
      this.editingItemDocs = Object.values(this.editingItem.docs || {});
    },
    handleEventChange(value) {
    this.newEvent = value
  },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.isLoadFile = true;
    },
    pushComplete(item) {
      API.post(this.api + 'api/auth/event/complete/' + item.id).then(response => {
          this.events.splice(this.events.indexOf(this.events.find(a => {
                a.id === this.deleteEvent.id
              })), 1),
              this.fillteringEvents.splice(this.fillteringEvents.find(a => {
              a.id === item.id
            }), 1)
          this.fillteringEvents.push(response.data.data)
          this.events.push(response.data.data)
        this.snakbarText = "Статус заявки изменен"
        this.snakbarVisible = true;
      })
    },
    pushNewYearEvent() {
      API.post(this.api + 'api/auth/year-event/', this.newYearEvent).then(response => {
        this.yearEvents.push(response.data.data);
        this.fillterEvents()
        this.newEvent.year_events_id = response.data.data.id;
        this.newYearEvent = {};
        this.newFailedEvent = {};
        this.loading = false;
        this.newYearEventDialog = false;
      })

    },
    getAuth() {
      if (localStorage.getItem('access_token') != null) {
        this.isAuth = true;
      }
    },
    fillterEvents() {
      this.fillteringEvents = this.events.filter(a => (new Date(a.startDate).getMonth() + 1) === this.currentMonth && a.status.name === 'assigned' && a.type === this.currentType.title);
      this.filteringYearEvents = this.events.filter(a => (new Date(a.startDate).getMonth() + 1) === this.currentMonth && a.status.name === 'created');
      this.failedEvents = this.events.filter(a => a.status.name === 'notCompleted');
    },
    changeEvent() {
      let item = this.failedEvents.filter(e => e.id === this.failedEventId).shift();
      this.filteringYearEvents.push(item.year_event);
      this.newEvent = item;
    },
    getEvents() {
      API.get(this.api + 'api/events/').then(response => {
        let eventsArray = [];
        response.data.message.forEach(event => {
          eventsArray.push(event);
        });
        this.events = eventsArray;
      })
    },
    getTypes() {
      API.get(this.api + 'api/types/').then(response => {
        response.data.message.forEach(type => {
          this.types.push(type);
        });
        this.currentType = this.types[0]
      })
    },
    getPlaces() {
      API.get(this.api + 'api/places/').then(response => {
        response.data.message.forEach(place => {
          this.places.push(place);
        });
      });
    },
    saveNewEvent() {
        this.pushNewEvent();
    },
    saveEditEvent() {
        this.pushEditEvent();
    },
    pushEditEvent() {
      API.put(this.api + 'api/events/', this.editingItem).then(this.editEventDialog = false,    this.snakbarText = "Заявка изменена",
        this.snakbarVisible = true,
      ).finally(this.fillterEvents())
    },
    createNewYearEvent(url) {
      API.post(this.api + 'api/events/', this.newYearEvent).then(response => {
        if(response.data.message.acknowledged){
          this.getEvents();
          this.fillterEvents();
          this.formData = new FormData();
          this.formData.append('file', this.file);
          this.formData.append('id', response.data.message.id.$oid);
        };
        this.newYearEvent = {};
        this.newYearEventDialog = false;
        this.snakbarText = "Запись успешно добавлена"
        this.snakbarVisible = true;
        this.getEvents();
        this.fillterEvents()
      })
    },
    saveNewYearEvent() {
      if (this.isLoadFile) {
        axios.post(this.api + 'api/events/file',
            this.formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(response => {
          this.isLoadFile = false;
        });
        this.getEvents();
        this.fillterEvents();
      }
    },
    pushNewEvent() {
      API.get(this.api + 'api/statuses/assigned').then(statusResponse => {
        this.newEvent.status = statusResponse.data.message;
        return API.put(this.api + 'api/events/', this.newEvent);
      }).then(eventResponse => {
        this.newEvent = {};  
        this.newFailedEvent = {};  
        this.newEventDialog = false;
        this.snakbarVisible = true;
        this.snakbarText = "Запись успешно добавлена.";
        this.getEvents();
      })
    },
    deleteItem(item) {
      this.deleteEvent = item;
      this.dialogDelete = true
    },
    deleteDocument(doc) {
      this.deleteDoc = doc;
      this.docsDialogDelete = true
    },
    
    deleteItemConfirm() {
      API.delete(this.api + 'api/events/' + this.deleteEvent.id)
          .then(
              this.getEvents(),
              this.fillterEvents(),
              this.snakbarVisible = true,
              this.snakbarText = "Запись успешно удалена.",
              this.dialogDelete = !this.dialogDelete,
          )
    },
    deleteDocConfirm() {
      const params = new URLSearchParams();
      params.append('id', this.editingItem.id);
      params.append('name', this.deleteDoc.name);

      axios.delete(this.api + 'api/events/file', {
          params: params
      }).then(
          this.snakbarVisible = true,
          this.snakbarText = "Запись успешно удалена."),
        this.docsDialogDelete = !this.docsDialogDelete
        this.editingItemDocs.splice(this.editingItemDocs.indexOf(this.deleteDoc), 1)
        this.editingItem.docs = this.editingItemDocs
    },
  }
})
</script>