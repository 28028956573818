import {createRouter, createWebHistory} from 'vue-router'
import MonthEventsView from '@/views/MonthEventsView.vue'
import Table from "@/components/TableEvents.vue"
import Users from "@/components/UsersTable.vue"
import YearEventsView from "@/views/YearEventsView.vue";
import Authorization from "@/components/Authorization.vue";
import EventsToDayView from "@/views/EventsToDayView.vue";

const routes = [
    {
        path: '/authorization',
        name: 'authorization',
        component: Authorization,
                meta: {title: 'Вход'},
         beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') == null) {
                next()
            } else {
                next()
            }
        }
    },
    {
        path: '/',
        name: 'home',
                meta: {title: 'План на день '},
        component: EventsToDayView,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') != null) {
                next()
            } else {
                next()
            }
        }
    },
    {
        path: '/day',
        name: 'day',
                meta: {title: 'Мероприятия на день'},
        component: EventsToDayView,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') != null) {
                next()
            } else {
                next()
            }
        }
    },
    {
        path: '/years',
        name: 'year-events',
                meta: {title: 'Годовой план'},
        component: YearEventsView,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') != null) {
                next()
            } else {
                next()
            }
        }
    },
    {
        path: '/month',
        name: 'month',
                meta: {title: 'Месячный план'},
        component: MonthEventsView,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') != null) {
                next()
            } else {
                next()
            }
        }
    },
    {
        path: '/table-events',
        name: 'table',
        component: Table,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') != null) {
                next()
            } else {
                next()
            }
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('access_token') != null) {
                next("")
            } else {
                next()
            }
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
