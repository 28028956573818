<template>
  <v-snackbar v-model="snakbarVisible" color="primary">
    {{ snakbarText }}

    <template v-slot:actions>
      <v-btn
          color="white"
          @click="snakbarVisible = false"
      >
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
  <v-card style="margin: 10px">
    <v-dialog height="auto" width="auto" v-model="newEventDialog">
      <v-card style="padding: 20px" elevation="11">
        <v-card-title>Создание нового мероприятия</v-card-title>
        <v-text-field variant="outlined"
                      label="Название мероприятия"
                      v-model="newEvent.name">
        </v-text-field>

        <v-autocomplete
                      :items="types"
                      item-title="title"
                      item-value="title"
                      variant="outlined"
                      label="Тип мероприятия"
                      v-model="newEvent.type">
        </v-autocomplete>

        <v-text-field variant="outlined"
                      type="date"
                      label="Дата мероприятия"
                      v-model="newEvent.startDate">
        </v-text-field>

        <v-row style="margin:0px">
          <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        </v-row>

        <v-row justify="center">
          <v-btn color="green" @click="pushNewEvent()">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog height="auto" width="400px" v-model="editEventDialog">
      <v-card style="padding: 20px;" elevation="11">
        <v-card-title style="text-align: center">Редактирование</v-card-title>
        <v-text-field variant="outlined"
                      label="Название мероприятия"
                      v-model="editingItem.name">

        </v-text-field>
        <v-text-field variant="outlined"
                      type="date"
                      label="Дата мероприятия"
                      v-model="editingItem.startDate">
                      
        </v-text-field>

        <v-data-table
          :headers="docsHeaders"
          :items="editingItemDocs">
          <template v-slot:[`item.name`]="{ item }" v-model="editingItem.docs">
            <a :href="getDocUrl(item.name)" target="_blank">
              {{ item.name }}
            </a>
          </template>
          <template v-slot:[`item.delete`]="{ item }" v-if="isAuth">
              <v-icon
                  size="small"
                  @click="deleteDocument(item)">
                mdi-delete
              </v-icon>
            </template>
        </v-data-table> 

        <v-row justify="center">
          <v-btn color="primary" @click="fileAdd = true">Добавить файл</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="saveEditEvent()">Сохранить</v-btn>
        </v-row>
        
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileAdd" max-width="400px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Добавление файла</v-card-title>
        <v-row justify="center" class="mt-4">
            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        </v-row>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="addFileToEvent()">Добавить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeFileAdd()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Удалить запись?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="deleteItemConfirm()">Удалить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeDelete()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="docsDialogDelete" max-width="300px">
      <v-card>
        <v-card-title style="text-align: center" class="text-h8">Удалить документ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="deleteDocConfirm()">Удалить</v-btn>
          <v-btn color="primary" variant="tonal" @click="closeDelete()">Отмена</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs color="primary" v-model="tab">
      <v-tab value="one">Таблица мероприятий</v-tab>
      <v-tab value="two">Список мероприятий</v-tab>
    </v-tabs>

    <v-card>
      <v-window v-model="tab">
        <v-window-item value="one">
          <v-row style="padding: 20px">
            <v-card
                elevation="4"
                v-for="(variant, i) in months"
                :key="i"
                class="year mx-auto"
                min-width="200"
                min-height="300px"
                max-width="400"
                style="margin: 2rem; font-size: 30px"
                v-if="this.isEventsLoad"
            >
              <v-card-title style="background-color: #6600FF;color: white;padding: 0px; font-size: 30px">
                <div class="text-overline"
                     style="padding: 0.5rem;font-size: 1.4rem !important; text-align: center; text-overline: 10px">
                  {{ variant }}
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-item style="padding-top: 0px; font-size: 1.4rem !important;">
                <div style="margin-top: 0.6rem;font-size: 1rem !important;" v-for="(event, i) in filterEvent(variant)"
                     class="text-caption">
                  <v-row>
                    <v-col cols="10" class="d-flex align-center">
                      <p class="mb-0">• {{ event.name }}</p>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="2">
                      <div v-if="event.docs && event.docs.length > 0">
                        <a 
                          v-for="(doc, index) in event.docs" 
                          :key="index" 
                          :href="getDocUrl(doc.name)" 
                          style="text-decoration: none; color: #6200EE; margin-right: 8px;"
                        >
                          <v-icon style="text-decoration: none; color: #6200EE;">mdi-file</v-icon>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                      style="margin: 1rem 0;"
                      :thickness="1"
                      class="border-opacity-50"
                  ></v-divider>
                </div>
              </v-card-item>
            </v-card>

          </v-row>
        </v-window-item>

        <v-window-item value="two">
          <v-row>
            <v-text-field
                style="margin: 15px"
                v-model="search"
                label="Поиск"
                prepend-inner-icon="mdi-magnify"
                single-line
                variant="outlined"
                hide-details
            ></v-text-field>
            <v-spacer/>
            <v-btn v-if="isAuth" color="primary" style="margin: 30px" @click="newEventDialog = !newEventDialog">Добавить
              запись
            </v-btn>
          </v-row>
          <v-data-table :search="search" :items="events" :headers="headers">
            <template v-slot:[`item.delete`]="{ item}" v-if="isAuth">
              <v-icon
                  size="small"
                  @click="editEventDialog = true, this.editingItem = item">
                mdi-pencil
              </v-icon>
              <v-icon
                  size="small"
                  @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-dialog>
            <v-card>
              <v-card-item>
                <v-text-field label="Мероприятие" variant="outlined"></v-text-field>
                <v-text-field variant="outlined"></v-text-field>
                <v-text-field variant="outlined"></v-text-field>
                <v-text-field variant="outlined"></v-text-field>
              </v-card-item>
              <v-card-actions style="justify-items: center">
                <v-btn color="primary">Добавить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-window-item>
      </v-window>
    </v-card>
  </v-card>
</template>

<script>
import API from '../auth.js'
import axios from "axios";

export default {
  watch: {
    editingItem() {
      this.getEditingDocs();
    },
  },
  mounted() {
    this.getAuth();
    this.getYearEvents();
    this.getAllTypes();
  },
  data() {
    return {
      tab: "one",
      months: ['сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
      ],
      api: process.env.VUE_APP_ENV_API,
      events: [],
      types: [],
      isEventsLoad: false,
      headers: [
        {title: 'Мероприятие', key: 'name', align: 'center'},
        {title: 'Дата мероприятия', key: 'startDate', align: 'center'},
        {title: '', key: 'delete', sortable: false, align: 'start'}
      ],
      docsHeaders:[
        {title: '', key:'name', sortable: false, align: 'center'},
        {title: '', key: 'delete', sortable: false, align: 'center'}
      ],
      search: '',
      newEventDialog: false,
      dialogDelete: false,
      docsDialogDelete: false,
      editEventDialog: false,
      fileAdd: false,
      snakbarVisible: false,
      snakbarText: '',

      formData: new FormData(),
      editingItem: {},
      editingItemDocs: [],
      deleteEvent: {},
      deleteDoc: {},
      newEvent: {},
      file: Object,
      isLoadFile: false,
      isAuth: true,
    }
  },
  methods: {
    addFileToEvent() {
      if (this.isLoadFile) {
        let formData = new FormData();
        formData.append('id', this.editingItem.id);
        formData.append('file', this.file);

        console.log("ID:", this.editingItem.id);
        console.log("File:", this.file);

        axios.post(this.api + 'api/events/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.isLoadFile = false;
          this.newEventDialog = false;
          this.snakbarText = "Файл успешно добавлен";
          this.snakbarVisible = true;
          this.getYearEvents(); 
          
          axios.get(this.api + 'api/events/' + this.editingItem.id)
            .then(response => {
              this.editingItem = response.data.message; 
              console.log(this.editingItem.docs)
              this.getEditingDocs();
            });
        });
      }
      this.fileAdd = !this.fileAdd;
    },
    closeDelete(){
      this.dialogDelete = false;
    },
    closeFileAdd(){
      this.fileAdd = false;
    },
    getDocUrl(fileName) {
      const baseUrl = "https://evdl.anosov.ru/docs/";
      return `${baseUrl}${fileName}`;
    },
    getEditingDocs() {
      this.editingItemDocs = Object.values(this.editingItem.docs || {});
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.isLoadFile = true;
    },
    getAuth() {
      if (localStorage.getItem('access_token') != null) {
        this.isAuth = true;
      }
    },
    getYearEvents() {
      API.get(this.api + 'api/events/').then(response => {
        let eventsArray = [];
        response.data.message.forEach(event => {
          eventsArray.push(event);
        });
        this.events = eventsArray;
        //для таблицы с мероприятиями
        this.isEventsLoad = true;
      })
    },
    filterEvent(month) {
      return this.events.filter(a => new Date(a.startDate).toLocaleString('default', {month: 'long'}) === month)
    },
    pushNewEvent(url) {
      API.post(this.api + 'api/events/', this.newEvent).then(response => {
        if(response.data.message.acknowledged){
          this.getYearEvents();
          this.formData = new FormData();
          this.formData.append('file', this.file);
          this.formData.append('id', response.data.message.id.$oid);
        };
        this.newEvent = {};
        this.newEventDialog = false;
        this.snakbarText = "Запись успешно добавлена"
        this.snakbarVisible = true;
        this.saveNewEvent()
      })
    },
    saveNewEvent() {
      if (this.isLoadFile) {
        axios.post(this.api + 'api/events/file',
            this.formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(response => {
          this.isLoadFile = false;
        });
        this.getYearEvents();
      }
    },
    pushEditEvent(url) {
      API.put(this.api + 'api/events/', this.editingItem)
          .then(response => {
            if(response.data.message.acknowledged){
              this.getYearEvents();
            };
            this.editEventDialog = false,
            this.snakbarText = "Запись успешно изменена",
            this.snakbarVisible = true
          })
    },
    saveEditEvent() {
        this.pushEditEvent();
    },
    deleteItem(item) {
      this.deleteEvent = item;
      this.dialogDelete = true
    },
    deleteDocument(doc) {
      this.deleteDoc = doc;
      this.docsDialogDelete = true
    },
    deleteItemConfirm() {
      API.delete(this.api + 'api/events/' + this.deleteEvent.id)
          .then(
              this.snakbarVisible = true,
              this.snakbarText = "Запись успешно удалена."),
          this.dialogDelete = !this.dialogDelete,
          this.events.splice(this.events.indexOf(this.deleteEvent), 1);
    },
    deleteDocConfirm() {
      const params = new URLSearchParams();
      params.append('id', this.editingItem.id);
      params.append('name', this.deleteDoc.name);

      axios.delete(this.api + 'api/events/file', {
          params: params
      }).then(
          this.snakbarVisible = true,
          this.snakbarText = "Запись успешно удалена."),
        this.docsDialogDelete = !this.docsDialogDelete
        this.editingItemDocs.splice(this.editingItemDocs.indexOf(this.deleteDoc), 1)
        this.editingItem.docs = this.editingItemDocs
    },
    getAllTypes(){
      API.get(this.api + 'api/types/').then(response => {
        response.data.message.forEach(type => {
          this.types.push(type);
        });
      })
    }
  }
}
</script>


<style scoped>
.year:hover {
  box-shadow: 8px 10px 100px 200px rgba(46, 46, 148, 0.2);
}
</style>